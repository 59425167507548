<template>
    <el-container>
        <el-header class="header">
            <el-text tag="b" style="font-size: 1em;">Team of the Match</el-text> 관리 페이지입니다.
        </el-header>
        <el-main style="width: 300px; margin: auto;">
            <el-form :model="form">
                <el-form-item>
                    <el-input v-model="form.username" size="large" placeholder="아이디" @keyup.enter="clickLogin" clearable />
                </el-form-item>
                <el-form-item>
                    <el-input type="password" v-model="form.password" size="large" @keyup.enter="clickLogin"  placeholder="비밀번호" clearable />
                </el-form-item>
                <el-form-item>
                    <el-button style="width: 100%" size="large" type="info" @click="clickLogin">
                        로그인
                    </el-button>
                </el-form-item>
            </el-form>
        </el-main>
    </el-container>
</template>

<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { login } from '@/api/auth';
import { ElNotification } from 'element-plus';
import store from '@/store/index';

const router = useRouter();

const form = reactive({
    username: null,
    password: null,
});

const clickLogin = async () => {
    try {
        let res = await login(form);

        const accessToken = res.data.accessToken;

        store.dispatch('setToken', accessToken);

        router.push({ name: 'main' });
    } catch(e) {
        ElNotification({
            title: '로그인 오류',
            message: e.response.data,
            duration: 2000,
            type: 'error'
        });
    }
}
</script>

<style scoped>
.header {
    font-size: 1.5em;
    padding: 25vh 0 10vh 0;
}
</style>