import axios from './index.js'

export function getOpenedDate(event) {
    return axios.get(`${event}-game/opened-date`);
}

export function getGame(event, date) {
    return axios.get(`${event}-game/game?date=${date}`);
}

export function closeGame(event, gameId, results) {
    return axios.patch(`${event}-game/close`, {
        'gameId': gameId,
        'results': results
    })
}

export function getClosedDate(event) {
    return axios.get(`${event}-game/closed-date`);
}

export function createGame(event, gameDate, matches) {
    return axios.post(`${event}-game/create`, {
        gameDate: gameDate,
        matches: matches
    });
}