<template>
  <nav-bar />
  <el-main>
    <div style="width: 55%; min-width: 700px; padding: 10vh 22.5% 0 22.5%">
      <el-row :gutter="20" justify="center">
        <el-col :span="6">
          <div
            class="grid-content"
            style="background: #fa6868"
            @click="moveToPage('account')"
          >
            계정 관리
          </div>
        </el-col>
        <el-col :span="6">
          <div
            class="grid-content"
            style="background: #fa9468"
            @click="moveToPage('prediction')"
          >
            예측 관리
          </div>
        </el-col>
        <el-col :span="6">
          <div
            class="grid-content"
            style="background: #fad168"
            @click="moveToPage('notifications')"
          >
            공지 / 알림 관리
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20" justify="center">
        <el-col :span="6">
          <div
            class="grid-content"
            style="background: #40cd5f"
            @click="moveToPage('report')"
          >
            신고 관리
          </div>
        </el-col>
        <el-col :span="6">
          <div
            class="grid-content"
            style="background: #68b4fa"
            @click="moveToPage('ranking')"
          >
            랭킹
          </div>
        </el-col>
        <el-col :span="6">
          <div
            class="grid-content"
            style="background: #6877fa"
            @click="moveToPage('season')"
          >
            시즌 관리
          </div>
        </el-col>
      </el-row>
    </div>
  </el-main>
</template>

<script setup>
import { useRouter } from "vue-router";
import NavBar from "@/components/NavBar";
import store from "@/store/index";

const router = useRouter();

const moveToPage = (name) => {
  if (!store.getters.isLogined) {
    router.push({ name: "login" });
    return;
  }
  router.push({ name: name });
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  font-size: 1.3rem;
  color: white;
  aspect-ratio: 1;
  border-radius: 40px;
  box-shadow: 0 0 10px 0 gray;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
