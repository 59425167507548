import { createStore } from 'vuex';
import { jwtDecode } from 'jwt-decode';

export default createStore({
    state: {
        id: null,
        accessToken: null
    },
    getters: {
        getToken(state) {
            return state.accessToken;
        },
        isLogined(state) {
            return state.id != null;
        }
    },
    mutations: {
        setToken(state, accessToken) {
            const decoded = jwtDecode(accessToken);
            state.id = decoded.id;
            state.accessToken = accessToken;
        },
        logout(state) {
            state.id = null;
            state.accessToken = null;
        }
    },
    actions: {
        setToken: ({ commit }, accessToken) => {
            commit('setToken', accessToken);
        },
        logout: ({ commit }) => {
            commit('logout');
        }
    }
})