<template>
    <nav-bar />
    <el-page-header @back="goBack" class="header" :icon="ArrowLeft">
        <template #title>
            <span>
                공지
            </span>
        </template>
        <template #content>
            <el-input type="text" placeholder="제목을 입력하세요" size="large" v-model="notification.title" style="width: 180%;" />
        </template>
        <template #extra>
            <el-button type="primary" :icon="Plus" size="large" plain @click="clickRegisterNotification">
                등록
            </el-button>
        </template>
        <template #default>
            <el-input type="textarea" placeholder="내용을 입력하세요" v-model="notification.content" rows="20" resize="none" />
        </template>
    </el-page-header>
</template>
<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import NavBar from '@/components/NavBar';
import { registerNotification } from '@/api/notification';
import { ElNotification } from 'element-plus';
import { ArrowLeft, Plus } from '@element-plus/icons-vue';

const router = useRouter();

const notification = reactive({
    title: '',
    content: ''
})

const goBack = () => {
    router.back();
}

const clickRegisterNotification = async () => {
    try {
        if(notification.title === '' || notification.content === '') {
            ElNotification({
                title: '미입력된 값',
                message: '제목 또는 내용을 입력해주세요.',
                duration: 2000,
                type: 'error'
            })
            return;
        }
        await registerNotification(notification);

        ElNotification({
            title: '등록 성공',
            message: '공지가 등록됐습니다.',
            duration: 2000,
            type: 'success'
        })
        router.back();
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            message: e.response.data,
            duration: 2000,
            type: 'error'
        })
    }
}
</script>
<style scoped>
.header {
    margin-top: 5vh;
    width: 40%;
    padding-left: 30%;
    min-width: 600px;
}
</style>