import store from "@/store/index";
import { createRouter, createWebHistory } from "vue-router";

import MainView from "@/views/MainView";
import LoginView from "@/views/LoginView";
import ManagerAddView from "@/views/ManagerAddView";
import PasswordChangeView from "@/views/PasswordChangeView";
import AccountView from "@/views/AccountView";
import PredictionView from "@/views/PredictionView";
import NotificationsView from "@/views/NotificationsView";
import NotificationView from "@/views/NotificationView";
import ReportView from "@/views/ReportView";
import RankingView from "@/views/RankingView";
import SeasonView from "@/views/SeasonView";
import NotificationRegisterViewVue from "@/views/NotificationRegisterView.vue";

const before = () => (next) => {
  if (store.state.id == null) next("/login");
};

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    else return { top: 0 };
  },
  routes: [
    {
      path: "/",
      name: "main",
      component: MainView,
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,
    },
    {
      path: "/manager/add",
      name: "manager-add",
      component: ManagerAddView,
      beforeEnter: before(),
    },
    {
      path: "/password/change",
      name: "password-change",
      component: PasswordChangeView,
      beforeEnter: before(),
    },
    {
      path: "/account",
      name: "account",
      component: AccountView,
      beforeEnter: before(),
    },
    {
      path: "/prediction",
      name: "prediction",
      component: PredictionView,
      beforeEnter: before(),
    },
    {
      path: "/notifications",
      name: "notifications",
      component: NotificationsView,
      beforeEnter: before(),
    },
    {
      path: "/notification/:id",
      name: "notification",
      component: NotificationView,
      beforeEnter: before(),
    },
    {
      path: "/notification-register",
      name: "notification-register",
      component: NotificationRegisterViewVue,
      beforeEnter: before(),
    },
    {
      path: "/report",
      name: "report",
      component: ReportView,
      beforeEnter: before(),
    },
    {
      path: "/ranking",
      name: "ranking",
      component: RankingView,
      beforeEnter: before(),
    },
    {
      path: "/season",
      name: "season",
      component: SeasonView,
      beforeEnter: before(),
    }
  ],
});

export default router;
