import axios from './index';

export function removeComment(id) {
    return axios.delete(`comment/delete/${id}`);
}

export function normalizeComment(id) {
    return axios.patch(`comment/normalize/${id}`);
}

export function getReportedComments(page) {
    return axios.get(`comment/reported?page=${page}`);
}