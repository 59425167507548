import axios from './index';

export function removePost(id) {
    return axios.delete(`post/delete/${id}`);
}

export function normalizePost(id) {
    return axios.patch(`post/normalize/${id}`);
}

export function getReportedPosts(page) {
    return axios.get(`post/reported?page=${page}`);
}