<template>
    <nav-bar />
    <el-container>
        <el-header class="header">
            <el-text tag="b" style="font-size: 1.5em;">
                관리자 계정 추가
            </el-text>
        </el-header>
        <el-main style="width: 300px; margin: auto;">
            <el-form :model="form">
                <el-form-item>
                    <el-input v-model="form.username" style="width: 73%"  size="large" placeholder="아이디" clearable />
                    <el-button style="width: 25%; margin-left: 2%;" size="large" type="info">중복확인</el-button>
                </el-form-item>
                <el-form-item>
                    <el-input type="password" v-model="form.password" size="large" placeholder="비밀번호" clearable />
                </el-form-item>
                <el-form-item>
                    <el-input type="password" v-model="form.passwordCheck" size="large" placeholder="비밀번호 확인" clearable />
                </el-form-item>
                <el-form-item>
                    <el-input v-model="form.passwordCheck" size="large" placeholder="이름" clearable />
                </el-form-item>
                <el-form-item>
                    <el-input v-model="form.passwordCheck" size="large" placeholder="전화번호" clearable />
                </el-form-item>
                <el-form-item>
                    <el-button style="width: 100%" size="large" type="info">
                        계정 추가
                    </el-button>
                </el-form-item>
            </el-form>
        </el-main>
    </el-container>
</template>
<script setup>
import { reactive } from 'vue';
import NavBar from '@/components/NavBar';

const form = reactive({
    username: '',
    password: '',
    passwordCheck: '',
    name: '',
    phoneNumber: ''
})
</script>
<style scoped>
.header {
    padding: 10vh 0 10vh 0;
}
</style>