<template>
    <nav-bar />
    <el-tabs v-model="activeName" class="tabs">
        <el-tab-pane label="공지" name="notification">
            <el-form-item>
                <el-button type="primary" :icon="Plus" @click="router.push({ name: 'notification-register' })">
                    공지 등록
                </el-button>
            </el-form-item>
            <el-table :data="notificationData" table-layout="auto" :style="{ width: '100%' }" @row-click="rowClick">
                <el-table-column align="center" prop="title" label="제목" />
                <el-table-column align="center" prop="createdDate" label="날짜" />
                <el-table-column align="center" label="삭제">
                    <template #default="remove">
                        <el-popconfirm confirm-button-text="네" cancel-button-text="아니오" title="정말로 삭제하시겠습니까?" @confirm="clickDeleteNotification(notificationData[remove.$index].id)" width="250">
                            <template #reference>
                                <el-button type="danger">삭제</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :page-count="pageCount" :current-page="currentPage" :total="total" layout="prev, pager, next"
            @current-change="clickNotifications"
            style="justify-content: center;" />
        </el-tab-pane>

        <el-tab-pane label="알림" name="push">
            <el-form-item>
                <el-select
                    v-model="screen"
                    placeholder="스크린"
                    style="width: 17%;"
                >
                    <el-option label="열린 예측" value="OpenPrediction" />
                    <el-option label="스코어" value="ScoreStackNavigator" />
                </el-select>
                <el-input
                    style="width: 23%;"
                    v-model="title"
                    placeholder="제목"
                />
                <el-input
                    style="width: 60%;"
                    v-model="body"
                    placeholder="내용"
                />

                <el-button type="info" :icon="Message" style="margin-top: 10px; width: 100%" @click="clickSendPush">
                    푸시 알림 보내기
                </el-button>
            </el-form-item>
        </el-tab-pane>
    </el-tabs>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import NavBar from '@/components/NavBar';
import { getNotifications, deleteNotification } from '@/api/notification';
import { sendPushForAll } from '@/api/fcm';
import { Plus, Message } from '@element-plus/icons-vue';
import { ElNotification } from 'element-plus';

const router = useRouter();

const activeName = ref('notification');
const notificationData = ref(null);
const pageCount = ref(0);
const currentPage = ref(0);
const total = ref(0);

const title = ref('');
const body = ref('');
const screen = ref('');

const clickNotifications = async (page) => {
    try {
        let res = await getNotifications(page - 1);
        notificationData.value = res.data.content;
        total.value = res.data.totalElements;
        pageCount.value = res.data.totalPages;
        currentPage.value = res.data.pageable.pageNumber + 1;
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            content: e.response.data,
            duration: 2000,
            type: 'error'
        })
    }
}

const rowClick = (row, column) => {
    if(column.label === '삭제') return;
    router.push({ path: `/notification/${row.id}` })
}

const clickDeleteNotification = async (id) => {
    try {
        await deleteNotification(id);

        ElNotification({
            title: '삭제 완료',
            message: '공지가 삭제되었습니다.',
            duration: 2000,
            type: 'success'
        });

        await clickNotifications(currentPage.value - 1);
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            message: '공지 삭제에 실패했습니다.',
            duration: 2000,
            type: 'error'
        });
    }
}

const clickSendPush = async () => {
    try {
        await sendPushForAll(title.value, body.value, screen.value);

        ElNotification({
            title: '푸시 성공',
            message: '푸시 알림이 전송되었습니다.',
            duration: 2000,
            type: 'success'
        });
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            message: e.response.data,
            duration: 2000,
            type: 'error'
        });
    }
}

clickNotifications(0);
</script>
<style scoped>
.tabs {
    margin-top: 5vh;
    width: 40%;
    padding-left: 30%;
    min-width: 600px;
}
</style>