import axios from './index.js'

export function findMembers(nickname, page) {
    return axios.get(`member/members?nickname=${nickname}&page=${page}`);
}

export function findManagers(name, page) {
    return axios.get(`manager/managers?name=${name}&page=${page}`);
}

export function stop(id) {
    return axios.patch(`member/stop/${id}`);
}