import axios from "./index";

export function registerNotification(data) {
  return axios.post("notification/post", data);
}

export function getNotifications(page) {
  return axios.get(`notification/notifications?page=${page}`);
}

export function getNotification(id) {
  return axios.get(`notification/notification/${id}`);
}

export function modifyNotification(data) {
  return axios.patch(`notification/modify/${data.id}`, data);
}

export function deleteNotification(id) {
  return axios.delete(`notification/delete/${id}`);
}
