<template>
  <nav-bar />
  <el-tabs v-model="activeName" class="tabs">
    <el-tab-pane label="고객 계정 관리" name="user">
      <el-form-item>
        <el-input
          style="width: 250px; margin-right: 5px"
          v-model="nickname"
          placeholder="닉네임 검색"
          @keyup.enter="clickFindMembers(0)"
        />
        <el-button type="info" :icon="Search" @click="clickFindMembers(0)">
          검색
        </el-button
        >
      </el-form-item>

      <el-table
        :data="memberData"
        table-layout="auto"
        :style="{ width: '100%' }"
      >
        <el-table-column align="center" prop="email" label="이메일" />
        <el-table-column align="center" prop="nickname" label="닉네임" />
        <el-table-column align="center" prop="createdDate" label="가입일" />
        <el-table-column align="center" label="7일 정지">
          <template #default="scope">
            <el-popconfirm
              confirm-button-text="네"
              cancel-button-text="아니오"
              title="정말로 정지하시겠습니까?"
              @confirm="clickStop(scope.row.id)"
              width="250"
            >
              <template #reference>
                <el-button type="danger">7일 정지</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :page-count="pageCountMember"
        :current-page="currentPageMember"
        :total="totalMember"
        layout="prev, pager, next"
        @current-change="clickFindMembers"
        style="justify-content: center"
      />
    </el-tab-pane>
    <el-tab-pane label="관리자 계정 관리" name="manager">
      <el-form-item>
        <el-input
          style="width: 250px; margin-right: 5px"
          v-model="name"
          placeholder="이름 검색"
          @keyup.enter="clickFindManagers(0)"
        />
        <el-button type="info" :icon="Search" @click="clickFindManagers(0)"
          >검색</el-button
        >
        <el-button
          type="primary"
          @click="router.push({ name: 'manager-add' })"
          :icon="Plus"
          >관리자 계정 추가</el-button
        >
      </el-form-item>

      <el-table
        :data="managerData"
        table-layout="auto"
        :style="{ width: '100%' }"
      >
        <el-table-column align="center" prop="username" label="아이디" />
        <el-table-column align="center" prop="name" label="이름" />
        <el-table-column align="center" prop="phoneNumber" label="전화번호" />
        <el-table-column align="center" prop="createdDate" label="가입일" />
        <!-- <el-table-column align="center" label="계정 삭제">
                    <el-button type="danger">계정 삭제</el-button>
                </el-table-column>
                <el-table-column align="center" label="비밀번호 재설정">
                    <el-button type="danger">비밀번호 재설정</el-button>
                </el-table-column> -->
      </el-table>

      <el-pagination
        :page-count="pageCountManager"
        :current-page="currentPageManager"
        :total="totalManager"
        layout="prev, pager, next"
        @current-change="clickFindManagers"
        style="justify-content: center"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Search, Plus } from "@element-plus/icons-vue";
import NavBar from "@/components/NavBar";
import { findMembers, findManagers, stop } from "@/api/account";
import { ElNotification } from "element-plus";

const router = useRouter();

const activeName = ref("user");

const nickname = ref("");
const totalMember = ref(0);
const pageCountMember = ref(0);
const currentPageMember = ref(0);
const memberData = ref(null);

const name = ref("");
const totalManager = ref(0);
const pageCountManager = ref(0);
const currentPageManager = ref(0);
const managerData = ref(null);

const initMember = async () => {
  try {
    let res = await findMembers("", 0);
    memberData.value = res.data.content;
    totalMember.value = res.data.totalElements;
    pageCountMember.value = res.data.totalPages;
    currentPageMember.value = res.data.pageable.pageNumber + 1;
  } catch (e) {
    ElNotification({
      title: "서버 오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }
};

const initManager = async () => {
  try {
    let res = await findManagers("", 0);
    managerData.value = res.data.content;
    totalManager.value = res.data.totalElements;
    pageCountManager.value = res.data.totalPages;
    currentPageManager.value = res.data.pageable.pageNumber + 1;
  } catch (e) {
    ElNotification({
      title: "서버 오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }
};

const clickFindMembers = async (page) => {
  try {
    let res = await findMembers(nickname.value, page - 1);
    memberData.value = res.data.content;
    totalMember.value = res.data.totalElements;
    pageCountMember.value = res.data.totalPages;
    currentPageMember.value = res.data.pageable.pageNumber + 1;
  } catch (e) {
    ElNotification({
      title: "서버 오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }
};

const clickFindManagers = async (page) => {
  try {
    let res = await findManagers(name.value, page - 1);
    managerData.value = res.data.content;
    totalManager.value = res.data.totalElements;
    pageCountManager.value = res.data.totalPages;
    currentPageManager.value = res.data.pageable.pageNumber + 1;
  } catch (e) {
    ElNotification({
      title: "서버 오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }
};

const clickStop = async (id) => {
  try {
    await stop(id);
    ElNotification({
      title: "정지 성공",
      duration: 2000,
      type: "success",
    });
  } catch (e) {
    ElNotification({
      title: "서버 오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }
};

initMember();
initManager();
</script>

<style scoped>
.tabs {
  margin-top: 5vh;
  width: 60%;
  padding-left: 20%;
  min-width: 600px;
}
</style>
