<template>
    <nav-bar />
    <el-tabs v-model="activeName" class="tabs">
        <el-tab-pane label="신고 글 관리" name="post">

            <el-table :data="postData" table-layout="auto" :style="{ width: '100%' }">
                <el-table-column align="center" prop="nickname" label="작성자"/>
                <el-table-column align="center" prop="title" label="제목" />
                <el-table-column align="center" prop="content" label="내용" show-overflow-tooltip />
                <el-table-column align="center" label="글 삭제">
                    <template #default="remove">
                        <el-popconfirm confirm-button-text="네" cancel-button-text="아니오" title="정말로 삭제하시겠습니까?" @confirm="clickRemovePost(remove.row.postId)" width="250">
                            <template #reference>
                                <el-button type="danger" size="small">글 삭제</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="작성자 정지">
                    <template #default="stop">
                        <el-popconfirm confirm-button-text="네" cancel-button-text="아니오" title="정말로 정지하시겠습니까?" @confirm="clickStop(stop.row.memberId)" width="250">
                            <template #reference>
                                <el-button type="danger" size="small">작성자 정지</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="글 정상화">
                    <template #default="normalize">
                        <el-popconfirm confirm-button-text="네" cancel-button-text="아니오" title="정말로 정상화하시겠습니까?" @confirm="clickNormalizePost(normalize.row.postId)" width="250">
                            <template #reference>
                                <el-button type="danger" size="small">글 정상화</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            
            <el-pagination :page-count="pageCountPost" :current-page="currentPagePost" :total="totalPost" layout="prev, pager, next"
            @current-change="clickFindReportedPosts"
            style="justify-content: center;" />
        </el-tab-pane>
        <el-tab-pane label="신고 댓글 관리" name="comment">
            
            <el-table :data="commentData" table-layout="auto" :style="{ width: '100%' }">
                <el-table-column align="center" prop="nickname" label="작성자" />
                <el-table-column align="center" prop="comment" label="내용" show-overflow-tooltip />
                <el-table-column align="center" label="댓글 삭제">
                    <template #default="remove">
                        <el-popconfirm confirm-button-text="네" cancel-button-text="아니오" title="정말로 삭제하시겠습니까?" @confirm="clickRemoveComment(remove.row.commentId)" width="250">
                            <template #reference>
                                <el-button type="danger" size="small">댓글 삭제</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="작성자 정지">
                    <template #default="stop">
                        <el-popconfirm confirm-button-text="네" cancel-button-text="아니오" title="정말로 정지하시겠습니까?" @confirm="clickStop(stop.row.memberId)" width="250">
                            <template #reference>
                                <el-button type="danger" size="small">작성자 정지</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="댓글 정상화">
                    <template #default="normalize">
                        <el-popconfirm confirm-button-text="네" cancel-button-text="아니오" title="정말로 정상화하시겠습니까?" @confirm="clickNormalizeComment(normalize.row.commentId)" width="250">
                            <template #reference>
                                <el-button type="danger" size="small">댓글 정상화</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination :page-count="pageCountComment" :current-page="currentPageComment" :total="totalComment" layout="prev, pager, next"
            @current-change="clickFindReportedComments"
            style="justify-content: center;" />
        </el-tab-pane>
    </el-tabs>
</template>

<script setup>
import { ref } from 'vue';
import NavBar from '@/components/NavBar';
import { stop } from '@/api/account';
import { removePost, normalizePost, getReportedPosts } from '@/api/post';
import { removeComment, normalizeComment, getReportedComments } from '@/api/comment';
import { ElNotification } from 'element-plus';

const activeName = ref('post');

const totalPost = ref(0);
const pageCountPost = ref(0);
const currentPagePost = ref(0);
const postData = ref(null);

const totalComment = ref(0);
const pageCountComment = ref(0);
const currentPageComment = ref(0);
const commentData = ref(null);

const clickStop = async (id) => {
    try {
        await stop(id);
        ElNotification({
            title: '정지 성공',
            duration: 2000,
            type: 'success'
        })
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            message: e.response.data,
            duration: 2000,
            type: 'error'
        });
    }
}

const clickRemovePost = async (id) => {
    try {
        await removePost(id);

        ElNotification({
            title: '글 삭제 성공',
            message: '글이 삭제되었습니다.',
            duration: 2000,
            type: 'success'
        });

        await clickFindReportedPosts(currentPagePost.value - 1);
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            message: e.response.data,
            duration: 2000,
            type: 'error'
        });
    }
}

const clickNormalizePost = async (id) => {
    try {
        await normalizePost(id);

        ElNotification({
            title: '글 정상화 성공',
            message: '글이 정상화되었습니다.',
            duration: 2000,
            type: 'success'
        });

        await clickFindReportedPosts(currentPagePost.value - 1);
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            message: e.response.data,
            duration: 2000,
            type: 'error'
        });
    }
}

const clickFindReportedPosts = async (page) => {
    try {
        let res = await getReportedPosts(page - 1);

        postData.value = res.data.content;
        totalPost.value = res.data.totalElements;
        pageCountPost.value = res.data.totalPages;
        currentPagePost.value = res.data.pageable.pageNumber + 1;
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            content: e.response.data,
            duration: 2000,
            type: 'error'
        })
    }
}

const clickRemoveComment = async (id) => {
    try {
        await removeComment(id);

        ElNotification({
            title: '댓글 삭제 성공',
            message: '댓글이 삭제되었습니다.',
            duration: 2000,
            type: 'success'
        });

        await clickFindReportedComments(currentPageComment.value - 1);
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            message: e.response.data,
            duration: 2000,
            type: 'error'
        });
    }
}

const clickNormalizeComment = async (id) => {
    try {
        await normalizeComment(id);

        ElNotification({
            title: '댓글 정상화 성공',
            message: '댓글이 정상화되었습니다.',
            duration: 2000,
            type: 'success'
        });

        await clickFindReportedComments(currentPageComment.value - 1);
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            message: e.response.data,
            duration: 2000,
            type: 'error'
        });
    }
}

const clickFindReportedComments = async (page) => {
    try {
        let res = await getReportedComments(page - 1);

        commentData.value = res.data.content;
        totalComment.value = res.data.totalElements;
        pageCountComment.value = res.data.totalPages;
        currentPageComment.value = res.data.pageable.pageNumber + 1;
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            content: e.response.data,
            duration: 2000,
            type: 'error'
        })
    }
}

clickFindReportedPosts(1);
clickFindReportedComments(1);
</script>
<style scoped>
.tabs {
    margin-top: 5vh;
    width: 60%;
    padding-left: 20%;
    min-width: 600px;
}
</style>