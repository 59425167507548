<template>
    <nav-bar />
    <el-page-header @back="goBack" class="header" :icon="ArrowLeft" v-loading="loading">
        <template #title>
            <span>
                공지
            </span>
        </template>
        <template #content>
            <el-input type="text" size="large" v-model="notification.title" style="width: 180%" />
        </template>
        <template #extra>
            <el-button type="warning" :icon="Edit" size="large" plain @click="clickModifyNotification">
                수정
            </el-button>
        </template>
        <template #default>
            <el-input type="textarea" v-model="notification.content" rows="20" resize="none" />
        </template>
    </el-page-header>
</template>
<script setup>
import { ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import NavBar from '@/components/NavBar';
import { getNotification, modifyNotification } from '@/api/notification';
import { ArrowLeft, Edit } from '@element-plus/icons-vue';
import { ElNotification } from 'element-plus';

const route = useRoute();
const router = useRouter();

const loading = ref(true);
const notification = reactive({
    id: 0,
    title: '',
    content: '',
    createdDate: ''
});

const clickNotification = async (id) => {
    try {
        let res = await getNotification(id);
        notification.id = res.data.id;
        notification.title = res.data.title;
        notification.content = res.data.content;
        notification.createdDate = res.data.createdDate;
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            message: e.response.data,
            duration: 2000,
            type: 'error'
        });
    } finally {
        loading.value = false;
    }
}

const clickModifyNotification = async () => {
    try {
        await modifyNotification(notification);

        ElNotification({
            title: '수정 성공',
            message: '공지가 수정되었습니다.',
            duration: 2000,
            type: 'success'
        });

        router.back();
    } catch(e) {
        ElNotification({
            title: '서버 오류',
            message: '공지 수정에 실패했습니다.',
            duration: 2000,
            type: 'error'
        });
    }
}


const goBack = () => {
    router.back();
}

clickNotification(route.params.id);
</script>
<style scoped>
.header {
    margin-top: 5vh;
    width: 40%;
    padding-left: 30%;
    min-width: 600px;
}
</style>