<template>
    <nav-bar />
    <el-tabs v-model="activeName" class="tabs">
        <el-tab-pane style="text-align: left;" label="시즌 관리" name="season">
            <el-form-item>
            <el-select
                v-model="year"
                placeholder="새 시즌"
                style="width: 100px; margin-right: 5px"
            />
            <el-popconfirm
                confirm-button-text="네"
                cancel-button-text="아니오"
                title="정말로 새로운 시즌을 시작하시겠습니까? 새로운 시즌을 시작하기 전에 모든 고객의 접속을 차단해야합니다."
                @confirm="clickCreateNewSeason"
                width="300"
            >
                <template #reference>
                    <el-button type="danger">
                        새 시즌 생성
                    </el-button>
                </template>
            </el-popconfirm>
            </el-form-item>
        </el-tab-pane>
    </el-tabs>
</template>

<script setup>
import { ref } from 'vue';
import NavBar from "@/components/NavBar";
import { createNewSeason } from '@/api/season';
import { ElNotification } from 'element-plus';

const activeName = ref('season');
const year = ref(new Date().getFullYear());

const clickCreateNewSeason = async () => {
    try {
        await createNewSeason(year.value);

        ElNotification({
            title: '시즌 생성 완료',
            message: '새로운 시즌이 시작되었습니다.',
            duration: 2000,
            type: 'success'
        });
    } catch (e) {
        ElNotification({
            title: '로그인 오류',
            message: e.response.data,
            duration: 2000,
            type: 'error'
        });
    }
}
</script>

<style scoped>
.tabs {
    margin-top: 5vh;
    width: 40%;
    padding-left: 30%;
    min-width: 600px;
}
</style>