<template>
    <el-menu :ellipsis="false" mode="horizontal">
        <el-menu-item style="font-size: 1.2em; font-weight: bold;" @click="router.push({name: 'main'})">
            Team of the Match
        </el-menu-item>
        <div class="flex-grow" />
        <el-menu-item v-if="isLogined" @click="clickLogout">
            로그아웃
        </el-menu-item>
        <el-menu-item v-else @click="router.push({name: 'login'})">
            로그인
        </el-menu-item>
    </el-menu>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

const isLogined = ref(false);

isLogined.value = store.getters.isLogined;

const clickLogout = () => {
    store.dispatch('logout');
    router.push({ name: 'login' });
}
</script>

<style scoped>
.flex-grow {
    flex-grow: 1;
}
</style>