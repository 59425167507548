import axios from 'axios';
import store from '@/store/index';

const instance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: true,
    timeout: 10000
});

instance.interceptors.request.use((config) => {
    config.headers.Authorization = store.getters.getToken;
    return config;
});

export default instance;