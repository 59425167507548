<template>
    <nav-bar />
    <el-tabs v-model="activeName" class="tabs">
        <el-tab-pane label="랭킹" name="ranking">
        <el-form-item>
            <el-select
                v-model="event"
                placeholder="종목 선택"
                style="width: 100px; margin-right: 5px"
            >
                <el-option label="축구" value="football" />
                <el-option label="해외축구" value="abroad-football" />
                <el-option label="야구" value="baseball" />
                <el-option label="해외야구" value="abroad-baseball" />
                <el-option label="농구" value="basketball" />
                <el-option label="해외농구" value="abroad-basketball" />
            </el-select>
            <el-select
                v-model="year"
                placeholder="시즌 선택"
                style="width: 100px; margin-right: 5px"
            >
                <el-option
                    v-for="year in years"
                    :key="year"
                    :label="year"
                    :value="year"
                />
            </el-select>
            <el-button type="info" :icon="Search" @click="initRanking">
                검색
            </el-button>
        </el-form-item>
        <el-table :data="rankingData" table-layout="auto" :style="{ width: '100%' }">
            <el-table-column align="center" prop="email" label="이메일" />
            <el-table-column align="center" prop="nickname" label="닉네임" />
            <el-table-column align="center" prop="score" label="점수" />
        </el-table>
        <el-pagination
            :page-count="pageCount"
            :current-page="currentPage"
            :total="total"
            layout="prev, pager, next"
            @current-change="clickGetRanking"
            style="justify-content: center"
        />
        </el-tab-pane>
    </el-tabs>
</template>
  
<script setup>
import NavBar from "@/components/NavBar";
import { ref } from 'vue';
import { getRanking } from "@/api/ranking";
import { ElNotification } from "element-plus";

const activeName = ref('ranking');
const event = ref(null);
const year = ref(new Date().getFullYear());
const years = ref([]);
const rankingData = ref(null);
const total = ref(0);
const pageCount = ref(0);
const currentPage = ref(0);

for (let year = 2024; year <= new Date().getFullYear(); year++) {
    years.value = [...years.value, year];
}

const initRanking = async () => {
    if(event.value == null) {
        ElNotification({
            title: "종목 선택 필요",
            message: "종목을 선택해주세요.",
            duration: 2000,
            type: "error",
        });
        return;
    }

    try {
        let res = await getRanking(event.value, year.value, 0);
        rankingData.value = res.data.content;
        total.value = res.data.totalElements;
        pageCount.value = res.data.totalPages;
        currentPage.value = res.data.pageable.pageNumber + 1;
    } catch (e) {
        ElNotification({
            title: "서버 오류",
            message: e.response.data,
            duration: 2000,
            type: "error",
        });
    }
}

const clickGetRanking = async (page) => {
    if(event.value == null) {
        ElNotification({
            title: "종목 선택 필요",
            message: "종목을 선택해주세요.",
            duration: 2000,
            type: "error",
        });
        return;
    }
    
    try {
        let res = await getRanking(event.value, year.value, page - 1);
        rankingData.value = res.data.content;
        total.value = res.data.totalElements;
        pageCount.value = res.data.totalPages;
        currentPage.value = res.data.pageable.pageNumber + 1;
    } catch (e) {
        ElNotification({
            title: "서버 오류",
            message: e.response.data,
            duration: 2000,
            type: "error",
        });
    }
}
</script>
  
<style scoped>
.tabs {
    margin-top: 5vh;
    width: 60%;
    padding-left: 20%;
    min-width: 600px;
}
</style>
  